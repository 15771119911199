import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SetTitleAction = {
  title: string;
};

export type PageTitleState = SetTitleAction;

const initialState: PageTitleState = {
  title: '',
};
const messageSlice = createSlice({
  name: 'pageTitle',
  initialState,
  reducers: {
    setPageTitle(state, action: PayloadAction<SetTitleAction>) {
      state.title = action.payload.title;
    },
  },
});

export const { setPageTitle } = messageSlice.actions;
export default messageSlice.reducer;
