import { Box, Container, Grid, LinearProgress } from '@mui/material';
import React, { FunctionComponent, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import ContactBlock from '../../components/contact-block/contact-block';
import TestPageLink from '../../components/test-page-link/TestPageLink';
import Message from '../../features/message/message';
import { showMessage } from '../../features/message/message-slice';
import PageTitle from '../../features/page-title/page-title';
import { resetSignInProcess } from '../../features/sign-in/sign-in-slice';
import { ChallengeParams } from '../../features/sign-in/types/ChallengeParams';
import { InstanceEnvironment } from '../../features/sign-in/types/InstanceEnvironment';
import { SignInStepType } from '../../features/sign-in/types/SignInStepType';
import AcceptTerms from './components/accept-terms/accept-terms';
import CheckUsernamePassword from './components/check-username-password/check-username-password';
import NewPasswordRequiredChallenge from './components/new-password-required-challenge/new-password-required-challenge';
import RecoverPassword from './components/recover-password/recover-password';
import RedirectToInstance from './components/redirect-to-instance/redirect-to-instance';
import RegisterConfirm from './components/register-confirm/register-confirm';
import ResetPassword from './components/reset-password/reset-password';
import SelectDomain from './components/select-domain/select-domain';
import { SoftwareTokenMfa } from './components/software-token-mfa/software-token-mfa';
import { AssociateTotpSoftwareToken } from './components/totp-setup/associate-totp-software-token';

type SignInPageProps = {
  isRecoverPassword?: boolean;
  isResetPassword?: boolean;
  isRegisterConfirm?: boolean;
};

const SignInPage: FunctionComponent<SignInPageProps> = (props) => {
  const { signInState, ...signInStore } = useAppSelector(
    (state: RootState) => state.signInStore
  );
  const { isRecoverPassword, isResetPassword, isRegisterConfirm } = props;
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const clpMessage = searchParams.get('clp_message');

  if (clpMessage) {
    dispatch(
      showMessage({
        type: 'warning',
        message: `clpMessage.${clpMessage}`,
      })
    );

    searchParams.delete('clp_message');
  }

  /**
   * we should reset sign in process if user wants to leave sign-in flow
   * componentWillUnmount
   */
  useEffect(
    () => () => {
      dispatch(resetSignInProcess());
    },
    []
  );

  return (
    <Box
      component="main"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        minHeight: '100%',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Container maxWidth="xs">
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              textAlign: 'center',
              paddingBottom: '5vh',
            }}
          >
            <PageTitle />
          </Grid>
          <Grid item xs={12}>
            <Message />
            {signInState === SignInStepType.Initialization && (
              <LinearProgress />
            )}
            {signInState === SignInStepType.ChoiceDomain && <SelectDomain />}
            {signInState === SignInStepType.MfaSetup && (
              <AssociateTotpSoftwareToken />
            )}
            {signInState === SignInStepType.SoftwareTokenMfa && (
              <SoftwareTokenMfa />
            )}
            {signInState === SignInStepType.CheckUsernamePassword &&
              !isRecoverPassword &&
              !isResetPassword &&
              !isRegisterConfirm && <CheckUsernamePassword />}
            {signInState === SignInStepType.NewPasswordRequired && (
              <NewPasswordRequiredChallenge
                newPasswordChallenge={
                  signInStore.challengeParams as ChallengeParams
                }
                selectedInstance={
                  signInStore.selectedInstance as InstanceEnvironment
                }
              />
            )}
            {signInState === SignInStepType.AcceptTerms && <AcceptTerms />}
            {(signInState === SignInStepType.RecoverPassword ||
              (signInState === SignInStepType.CheckUsernamePassword &&
                isRecoverPassword)) && <RecoverPassword />}
            {(signInState === SignInStepType.ResetPassword ||
              (signInState === SignInStepType.CheckUsernamePassword &&
                isResetPassword)) && <ResetPassword />}
            {(signInState === SignInStepType.RegisterConfirm ||
              (signInState === SignInStepType.CheckUsernamePassword &&
                isRegisterConfirm)) && <RegisterConfirm />}
            {signInState === SignInStepType.SignedIn && <RedirectToInstance />}
          </Grid>
        </Grid>
      </Container>
      {signInState !== SignInStepType.SignedIn &&
        signInState !== SignInStepType.MfaSetup && (
          <Container
            maxWidth="xs"
            sx={{
              textAlign: 'center',
            }}
          >
            <ContactBlock />
            <TestPageLink />
          </Container>
        )}
    </Box>
  );
};

SignInPage.defaultProps = {
  isRecoverPassword: false,
  isResetPassword: false,
  isRegisterConfirm: false,
};

export default SignInPage;
