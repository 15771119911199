import { Box, Link } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';

type TestPageLinkProps = {};

const TestPageLink: FunctionComponent<TestPageLinkProps> = () => {
  const { t, i18n } = useTranslation();
  const [url, setUrl] = useState('/testpage.html');

  const { selectedInstance } = useAppSelector(
    (state: RootState) => state.signInStore
  );

  const getInstanceEnvironment = (urlSelected: string) =>
    urlSelected.split('.')[0];

  const getReferrerHost = () => {
    if (document.referrer) {
      const referrerURL = new URL(document.referrer);
      return referrerURL.hostname;
    }
    return null;
  };

  const getCookieHost = () => {
    const name = 'planerio-prev-instance-host=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookies = decodedCookie.split(';').map((cookie) => cookie.trim());

    const cookie = cookies.find((c) => c.startsWith(name));
    return cookie ? cookie.substring(name.length) : null;
  };

  useEffect(() => {
    const host = selectedInstance?.host || getReferrerHost() || getCookieHost();
    if (host) {
      setUrl(`/testpage.html?instance=${getInstanceEnvironment(host)}`);
    } else {
      setUrl('/testpage.html');
    }
  }, [selectedInstance]);

  return (
    <Box sx={{ display: { xs: 'none', md: 'block', lg: 'block' } }}>
      <Link href={url} target="_blank" underline="hover">
        {t('testPage.title')}
      </Link>
    </Box>
  );
};

export default TestPageLink;
