import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../app/hooks';
import ChangePasswordForm from '../../../../components/change-password-form/change-password-form';
import { showMessage } from '../../../../features/message/message-slice';
import { setPageTitle } from '../../../../features/page-title/page-title-slice';
import {
  confirmForgotPasswordCentralAuth,
  signInCentralAuth,
} from '../../../../features/sign-in/services/direct-auth';
import { useCheckUserByUserNameMutation } from '../../../../features/sign-in/sign-in-api-slice';
import {
  setSelectedInstance,
  setUsername,
} from '../../../../features/sign-in/sign-in-slice';
import { InstanceEnvironment } from '../../../../features/sign-in/types/InstanceEnvironment';
import { logError } from '../../../../helper/LoggerHelper';
import { handleLoginResponse } from '../../../../helper/LoginHelper';
import { getMessagesFromResponseError } from '../../../../helper/request-helper';

type RecoverPasswordProps = {};

const RecoverPassword: FunctionComponent<RecoverPasswordProps> = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const [checkUserByUsername] = useCheckUserByUserNameMutation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(setPageTitle({ title: 'recoverPassword.pageTitle' }));
  }, []);

  const getInstance = async (
    username: string,
    canonicalHostname: string
  ): Promise<InstanceEnvironment | undefined> => {
    const availableInstances = await checkUserByUsername({ username }).unwrap();

    // as a result here should be always one instance
    const suggestedInstances = availableInstances.filter(
      (instance) => instance.host === canonicalHostname
    );

    return suggestedInstances[0];
  };

  const onSubmitHandler = async (password: string) => {
    try {
      setIsLoading(true);

      const username = searchParams.get('username');
      const code = searchParams.get('code');
      const requestedCanonicalHostname = searchParams.get('host');
      if (username && code && requestedCanonicalHostname) {
        const selectedInstance = await getInstance(
          username,
          requestedCanonicalHostname
        );

        if (!selectedInstance) {
          dispatch(
            showMessage({
              type: 'error',
              message: 'recoverPassword.missingParams',
            })
          );

          return;
        }

        if (selectedInstance.maintenanceMode) {
          dispatch(
            showMessage({
              type: 'error',
              message: 'login.instanceUnderMaintenance',
            })
          );

          return;
        }

        dispatch(setSelectedInstance(selectedInstance));
        dispatch(setUsername(username));

        await confirmForgotPasswordCentralAuth(
          {
            password,
            email: username,
            confirmationCode: code,
          },
          selectedInstance
        );

        const data = await signInCentralAuth(
          username,
          password,
          selectedInstance,
          i18n.language
        );
        setIsLoading(false);
        handleLoginResponse(data, dispatch);
      } else {
        setIsLoading(false);
        dispatch(
          showMessage({
            type: 'error',
            message: t('recoverPassword.missingParams'),
          })
        );
      }
    } catch (e) {
      setIsLoading(false);
      logError(e);
      const errorMessages: string[] = getMessagesFromResponseError(e);

      dispatch(
        showMessage({
          type: 'error',
          messages: errorMessages,
        })
      );
    }
  };

  return (
    <ChangePasswordForm
      processingFormData={isLoading}
      onSubmitHandler={onSubmitHandler}
    />
  );
};
export default RecoverPassword;
