import { Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';

interface MessageProps {}

const PageTitle: FunctionComponent<MessageProps> = () => {
  const { t } = useTranslation();
  const { title } = useAppSelector((state: RootState) => state.pageTitle);

  return (
    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
      {t(title)}
    </Typography>
  );
};

export default PageTitle;
