import { Grid, Link, Stack } from '@mui/material';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

const Footer: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      sx={{
        minHeight: '5vh',
      }}
    >
      <Grid item xs={12} sm={9} md={10}>
        <Stack direction="row" spacing={2}>
          <Link
            color="inherit"
            underline="hover"
            href="https://planerio.de/AGB/"
          >
            {t('footer.termsAndConditions')}
          </Link>
          <Link
            color="inherit"
            underline="hover"
            href="https://planerio.de/de/app/privacy"
          >
            {t('footer.privacy')}
          </Link>
          <Link
            color="inherit"
            underline="hover"
            href="https://planerio.de/impressum/"
          >
            {t('footer.imprint')}
          </Link>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        {`© Planerio ${new Date().getFullYear()}`}
      </Grid>
    </Grid>
  );
};

export default Footer;
