import { Dispatch } from '@reduxjs/toolkit';
import { SignInResponseCentralAuth } from '../features/sign-in/services/direct-auth';
import {
  setChallengeParams,
  updateSignInState,
} from '../features/sign-in/sign-in-slice';
import { SignInChallengeType } from '../features/sign-in/types/SignInChallengeType';
import { SignInStepType } from '../features/sign-in/types/SignInStepType';

export const handleLoginResponse = (
  response: SignInResponseCentralAuth,
  dispatch: Dispatch
) => {
  if (response.challenge) {
    const { challenge } = response;

    dispatch(setChallengeParams(challenge));
    switch (challenge.name) {
      case SignInChallengeType.NEW_PASSWORD_REQUIRED:
        dispatch(updateSignInState(SignInStepType.NewPasswordRequired));
        break;

      case SignInChallengeType.TERMS_AND_PRIVACY_POLICY_CHALLENGE:
        dispatch(updateSignInState(SignInStepType.AcceptTerms));
        break;

      case SignInChallengeType.MFA_SETUP:
        dispatch(updateSignInState(SignInStepType.MfaSetup));
        break;

      case SignInChallengeType.SOFTWARE_TOKEN_MFA:
        dispatch(updateSignInState(SignInStepType.SoftwareTokenMfa));
        break;

      default:
        throw new Error(`Undefined challenge "${challenge.name}"`);
    }
  } else {
    dispatch(updateSignInState(SignInStepType.SignedIn));
  }
};
