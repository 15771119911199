import { Breadcrumb, BreadcrumbHint } from '@sentry/react';

export const addRequestId = (
  breadcrumb: Breadcrumb,
  hint: BreadcrumbHint | undefined
) => {
  const headers = hint?.response?.headers;
  let requestId = null;

  if (headers) {
    requestId = headers.get('X-Request-Id') || headers.get('x-amzn-trace-id');
  }

  if (typeof breadcrumb.data !== 'object') {
    breadcrumb.data = {};
  }

  breadcrumb.data.requestId = requestId;
};
