import { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { ComponentException } from '../../errors/ComponentException';
import { showMessage } from '../../features/message/message-slice';
import { loginUsingSSOCodeCentralAuth } from '../../features/sign-in/services/direct-auth';
import { useCheckInstanceConfigMutation } from '../../features/sign-in/sign-in-api-slice';
import {
  setSelectedInstance,
  setUsername,
  updateSignInState,
} from '../../features/sign-in/sign-in-slice';
import { SignInStepType } from '../../features/sign-in/types/SignInStepType';
import { logError } from '../../helper/LoggerHelper';
import { handleLoginResponse } from '../../helper/LoginHelper';
import { getMessagesFromResponseError } from '../../helper/request-helper';

type Props = {
  children: any;
};

export const CallbackPage: FunctionComponent<Props> = (props) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const stateId = searchParams.get('state');
  const instance = searchParams.get('instance');
  const errorDescription = searchParams.get('error_description');
  const { i18n } = useTranslation();
  const [checkInstanceConfig] = useCheckInstanceConfigMutation();

  const handleParameters = async () => {
    if (errorDescription) {
      throw new ComponentException(errorDescription);
    }

    if (!stateId || !code || !instance) {
      throw new ComponentException('state and code and instance are required.');
    }

    console.debug('Loading environments...');

    const targetInstance = await checkInstanceConfig({
      host: instance,
    }).unwrap();

    console.debug(`Target instance: ${JSON.stringify(targetInstance)}`);

    if (!targetInstance) {
      throw new ComponentException(
        `Instance is not found by host: ${instance}`
      );
    }

    dispatch(setSelectedInstance(targetInstance));

    const data = await loginUsingSSOCodeCentralAuth(
      instance,
      code,
      stateId,
      i18n.language
    );

    if (data.challenge && data.challenge.username) {
      dispatch(setUsername(data.challenge.username));
    }

    handleLoginResponse(data, dispatch);
  };

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(updateSignInState(SignInStepType.Initialization));

    handleParameters().catch((e) => {
      logError(e);
      const errorMessages: string[] = getMessagesFromResponseError(e);

      dispatch(
        showMessage({
          type: 'error',
          messages: errorMessages,
        })
      );

      navigate(`/${i18n.language}/sso`, { replace: true });
    });
  }, [code, stateId]);

  return props.children;
};
