import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import BackHomeButton from '../../../../components/back-home-button/back-home-button';
import {
  clearMessage,
  showMessage,
} from '../../../../features/message/message-slice';
import { setPageTitle } from '../../../../features/page-title/page-title-slice';
import {
  acceptTermsCentralAuth,
  acceptTermsLegacy,
} from '../../../../features/sign-in/services/direct-auth';
import { acceptTermsAndConditions } from '../../../../features/sign-in/sign-in-slice';
import { logError } from '../../../../helper/LoggerHelper';
import { getMessagesFromResponseError } from '../../../../helper/request-helper';

type AcceptTermsProps = {};

const AcceptTerms: FunctionComponent<AcceptTermsProps> = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { username, selectedInstance, ...signInStore } = useAppSelector(
    (state: RootState) => state.signInStore
  );
  const [accepted, setAccepted] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setPageTitle({ title: 'acceptTerms.pageTitle' }));

    return () => {
      dispatch(clearMessage());
    };
  }, []);

  const handleChange = () => {
    setAccepted(!accepted);
  };

  const acceptTermsHandler = async () => {
    try {
      if (accepted && selectedInstance) {
        if (!username) {
          throw new Error('Username should be defined');
        }
        const instanceName = selectedInstance?.host?.split('.')[0];
        if (instanceName !== undefined) {
          if (selectedInstance.useCentralAuth) {
            if (!signInStore.challengeParams) {
              throw new Error('Challenge params not found.');
            }

            await acceptTermsCentralAuth(
              selectedInstance,
              i18n.language,
              signInStore.challengeParams
            );
          } else {
            await acceptTermsLegacy(username, selectedInstance);
          }
        }

        dispatch(acceptTermsAndConditions());
      }
    } catch (e) {
      logError(e);
      const errorMessages: string[] = getMessagesFromResponseError(e);

      dispatch(
        showMessage({
          type: 'error',
          messages: errorMessages,
        })
      );
    }
  };

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="body1" gutterBottom>
        {t('acceptTerms.message')}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Link href="https://planerio.de/AGB/" target="_blank">
          {t('acceptTerms.link1')}
        </Link>
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Link
          href="https://planerio.de/wp-content/uploads/2022/05/Planerio-Datenschutzerkla%CC%88rung-1.pdf"
          target="_blank"
        >
          {t('acceptTerms.link2')}
        </Link>
      </Typography>
      <Box>
        <FormGroup>
          <FormControlLabel
            id="acceptTermsLabel"
            label={t('acceptTerms.checkboxText') as string}
            style={{ margin: 0 }}
            control={
              <Checkbox
                id="acceptTermsCheckbox"
                value="accepted"
                checked={accepted}
                style={{ marginRight: '8px' }}
                onChange={handleChange}
              />
            }
          />
        </FormGroup>
      </Box>
      <Button
        id="acceptTermsBtn"
        disabled={!accepted}
        onClick={acceptTermsHandler}
      >
        {t('acceptTerms.button')}
      </Button>
      <BackHomeButton />
    </Stack>
  );
};
export default AcceptTerms;
