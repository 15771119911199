import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../app/hooks';
import BackHomeButton from '../../../../components/back-home-button/back-home-button';
import ChangePasswordForm from '../../../../components/change-password-form/change-password-form';
import { BadRequestException } from '../../../../errors/BadRequestException';
import { ComponentException } from '../../../../errors/ComponentException';
import { RuntimeException } from '../../../../errors/RuntimeException';
import { StatusCode } from '../../../../errors/StatusCode';
import { showMessage } from '../../../../features/message/message-slice';
import { setPageTitle } from '../../../../features/page-title/page-title-slice';
import { directResetPasswordRequest } from '../../../../features/sign-in/services/direct-auth';
import { logError } from '../../../../helper/LoggerHelper';

type ResetPasswordProps = {};

const ResetPassword: FunctionComponent<ResetPasswordProps> = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { instanceHostName, token } = useParams();
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(false);
  const [showResetPasswordForm, setShowResetPasswordForm] =
    useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setPageTitle({ title: 'resetPassword.pageTitle' }));

    return () => {};
  }, []);

  const onSubmitHandler = async (password: string) => {
    try {
      // TODO: this check should be happens directly before mount component
      if (!instanceHostName || !token) {
        throw new ComponentException('resetPassword.missingParams');
      }

      setIsLoading(true);
      setDisabledSubmit(true);

      await directResetPasswordRequest(instanceHostName, token, password);
      dispatch(
        showMessage({
          type: 'success',
          message: 'resetPassword.success',
        })
      );
      setDisabledSubmit(false);
      setIsLoading(false);
      navigate(`/${i18n.language}/`);
    } catch (e) {
      logError(e);
      let errorMessage: string | undefined;
      if (e instanceof BadRequestException) {
        switch (e.code) {
          case StatusCode.NOT_FOUND:
            setShowResetPasswordForm(false);
            errorMessage = 'resetPassword.error';
            break;
          case StatusCode.CORS_PROBLEM:
            errorMessage = 'login.notOnWhitelist';
            break;
          default:
            errorMessage = e.message;
            break;
        }
      } else if (
        e instanceof RuntimeException &&
        e.code === StatusCode.NOT_FOUND
      ) {
        // user not found
        // invalid link or the reset password toke is already expired
        errorMessage = 'resetPassword.error';
      } else {
        errorMessage =
          (e as Error).message ?? 'genericErrors.temporaryProblems';
      }

      setDisabledSubmit(false);
      setIsLoading(false);

      dispatch(
        showMessage({
          type: 'error',
          message: errorMessage,
        })
      );
    }
  };

  return (
    <>
      {showResetPasswordForm && (
        <ChangePasswordForm
          disabledSubmit={disabledSubmit}
          processingFormData={isLoading}
          onSubmitHandler={onSubmitHandler}
        />
      )}
      {!showResetPasswordForm && (
        <div>
          <BackHomeButton />
        </div>
      )}
    </>
  );
};
export default ResetPassword;
