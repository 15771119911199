import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useOriginInstance = () => {
  const [originInstance, setOriginInstance] = useState<string | null>(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has('originInstance')) {
      setOriginInstance(searchParams.get('originInstance'));
      searchParams.delete('originInstance');

      window.history.replaceState(
        null,
        '',
        `${window.location.pathname}?${searchParams.toString()}`
      );
    }
  }, []);

  return originInstance;
};
