import { Box, Stack, TextField, Typography } from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import {
  clearMessage,
  showMessage,
} from '../../../../features/message/message-slice';
import { setPageTitle } from '../../../../features/page-title/page-title-slice';
import { respondSoftwareTokenMfa } from '../../../../features/sign-in/services/direct-auth';
import { logError } from '../../../../helper/LoggerHelper';
import { handleLoginResponse } from '../../../../helper/LoginHelper';
import { getMessagesFromResponseError } from '../../../../helper/request-helper';
import { SubmitForm } from '../form/submit-form';

type SoftwareTokenMfaProps = {};

type FormValues = {
  userCode: string;
};

export const SoftwareTokenMfa: FunctionComponent<
  SoftwareTokenMfaProps
> = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const [submitIsLoading, setSubmitIsLoading] = useState<boolean>(false);

  const { selectedInstance, challengeParams } = useAppSelector(
    (state: RootState) => state.signInStore
  );

  useEffect(() => {
    dispatch(setPageTitle({ title: 'softwareTokenMfa.pageTitle' }));

    return () => {
      dispatch(clearMessage());
    };
  }, []);

  const onSubmit: SubmitHandler<FormValues> = async (formData) => {
    setSubmitIsLoading(true);

    try {
      if (
        !challengeParams?.username ||
        !challengeParams?.session ||
        !selectedInstance
      ) {
        return;
      }

      const data = await respondSoftwareTokenMfa(
        selectedInstance,
        challengeParams.username,
        i18n.language,
        formData.userCode,
        challengeParams.session
      );
      handleLoginResponse(data, dispatch);
    } catch (e) {
      setSubmitIsLoading(false);
      logError(e);
      const errorMessages: string[] = getMessagesFromResponseError(e);
      dispatch(
        showMessage({
          type: 'error',
          messages: errorMessages,
        })
      );
    }
  };

  return (
    <Stack direction="column" spacing={2} alignItems="center">
      <Typography variant="body1" gutterBottom>
        {t('softwareTokenMfa.description')}
      </Typography>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <SubmitForm
          label={t('softwareTokenMfa.form.userCode.label')}
          submitBtnText={t('softwareTokenMfa.form.submit.label')}
          submitIsLoading={submitIsLoading}
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            id="softwareTokenInputField"
            autoFocus
            inputProps={{ tabIndex: 1 }}
            placeholder={t('softwareTokenMfa.form.userCode.placeholder')}
            autoComplete="one-time-code"
            fullWidth
            helperText={errors.userCode?.message}
            error={!!errors.userCode?.message}
            {...register('userCode', {
              onChange: (e) => {
                e.target.value = e.target.value.trim();
              },
              required: t(
                'softwareTokenMfa.form.userCode.error.empty'
              ) as string,
            })}
          />
        </SubmitForm>
      </Box>
    </Stack>
  );
};
