import { GeneralError } from './GeneralError';
import { StatusCode } from './StatusCode';

export class CorsRequestException extends GeneralError {
  public constructor(
    message: string,
    public readonly code: StatusCode = StatusCode.CORS_PROBLEM
  ) {
    super(message);
  }
}
