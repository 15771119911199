import { Button, ButtonProps } from '@mui/material';
import { FunctionComponent, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { clearMessage } from '../../features/message/message-slice';
import { updateSignInState } from '../../features/sign-in/sign-in-slice';
import { SignInStepType } from '../../features/sign-in/types/SignInStepType';

const BackHomeButton: FunctionComponent<ButtonProps> = () => {
  const dispatch = useAppDispatch();

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const onClickBackHome: MouseEventHandler<HTMLButtonElement> = (e): void => {
    e.preventDefault();
    dispatch(updateSignInState(SignInStepType.CheckUsernamePassword));
    dispatch(clearMessage());
    navigate(`/${i18n.language}/`, { replace: true });
  };

  return (
    <Button variant="text" id="backHomeButton" onClick={onClickBackHome}>
      {t('backHomeButton.label')}
    </Button>
  );
};

export default BackHomeButton;
