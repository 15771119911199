import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { InstanceEnvironment } from './types/InstanceEnvironment';

export const apiSlice = createApi({
  reducerPath: 'check-username',
  tagTypes: ['InstanceEnvironmentList'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.location.origin.replace(
      '//login.',
      '//api-gw.'
    )}/planerio/central-login/api/`,
  }),
  endpoints: (builder) => ({
    checkUserByUserName: builder.mutation<
      Array<InstanceEnvironment>,
      { username: string }
    >({
      query: (body) => ({
        url: '/service/checkUserByUserName',
        method: 'POST',
        body,
      }),
      transformResponse: (response: Array<InstanceEnvironment>) => response,

      invalidatesTags: ['InstanceEnvironmentList'],
    }),
    checkUserByEmail: builder.mutation<
      Array<InstanceEnvironment>,
      { email: string }
    >({
      query: (body) => ({
        url: '/service/checkUserByEmail',
        method: 'POST',
        body,
      }),
      transformResponse: (response: Array<InstanceEnvironment>) => response,

      invalidatesTags: ['InstanceEnvironmentList'],
    }),
    checkInstanceConfig: builder.mutation<
      InstanceEnvironment,
      { host: string }
    >({
      query: (body) => ({
        url: '/service/instanceConfig',
        method: 'POST',
        body,
      }),
      transformResponse: (response: InstanceEnvironment) => response,
    }),
  }),
});

export const {
  useCheckInstanceConfigMutation,
  useCheckUserByUserNameMutation,
  useCheckUserByEmailMutation,
} = apiSlice;
