import { LinearProgress } from '@mui/material';
import { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { setPageTitle } from '../../../../features/page-title/page-title-slice';
import { redirect } from '../../../../helper/RedirectHelper';

type RedirectToInstanceProps = {};

const RedirectToInstance: FunctionComponent<RedirectToInstanceProps> = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();

  const { selectedInstance } = useAppSelector(
    (state: RootState) => state.signInStore
  );

  /**
   * We need this normalization to get the correct language
   * because for some random users the browser language is set to `de-DE`
   */
  const getNormalizedLanguage = (language: string) => language.split('-')[0];

  useEffect(() => {
    if (selectedInstance) {
      dispatch(setPageTitle({ title: 'redirectToInstance.pageTitle' }));

      redirect(
        `//${selectedInstance.host}/${getNormalizedLanguage(i18n.language)}/?from_login=true`
      );
    }
  }, []);

  return (
    <>
      {selectedInstance && <LinearProgress />}
      {!selectedInstance && <p>{t('redirectToInstance.error')}</p>}
    </>
  );
};

export default RedirectToInstance;
