import { Box, Link } from '@mui/material';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

type ContactBlockProps = {};

const ContactBlock: FunctionComponent<ContactBlockProps> = () => {
  const { t, i18n } = useTranslation();

  const getLink = () => {
    if (i18n.language === 'en') {
      return 'https://planerio.com/contact/';
    }
    return 'https://planerio.de/kontakt/';
  };

  return (
    <Box sx={{ display: { xs: 'none', md: 'block', lg: 'block' } }}>
      <p>{t('contact.text1')}</p>
      <p>{t('contact.text2')}</p>
      <Link href={getLink()} target="_blank" underline="hover">
        {t('contact.button')}
      </Link>
    </Box>
  );
};

export default ContactBlock;
