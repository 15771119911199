import { BadRequestException } from '../errors/BadRequestException';
import { CorsRequestException } from '../errors/CorsRequestException';
import { IPNotWhitelistedException } from '../errors/IPNotWhitelistedException';
import { RuntimeException } from '../errors/RuntimeException';
import { StatusCode } from '../errors/StatusCode';

export const getMessagesFromResponseError = (e: any) => {
  if (e instanceof CorsRequestException) {
    return ['genericErrors.temporaryProblems'];
  }

  if (e instanceof IPNotWhitelistedException) {
    return ['login.notOnWhitelist'];
  }

  if (e instanceof BadRequestException && e.code === StatusCode.BAD_REQUEST) {
    // in case of validation exception from the API
    return (
      (e.body?.messages as string[]) || [
        e.message || 'genericErrors.temporaryProblems',
      ]
    );
  }

  if (
    e instanceof Error &&
    !(e instanceof TypeError) &&
    !(e instanceof RuntimeException)
  ) {
    // in case of error message but not TypeError
    return [e.message];
  }

  return ['genericErrors.temporaryProblems'];
};

const handleResponse = async (response: Response): Promise<any> => {
  if (response.status === 403) {
    if (response.headers.get('x-planerio-ip-whitelist-active') === 'true') {
      throw new IPNotWhitelistedException(403, 'notOnWhitelist', {
        loginURL: response.url,
      });
    } else {
      throw new BadRequestException(403, 'AccessDenied', {
        loginURL: response.url,
      });
    }
  }

  try {
    if (response.status === 204) {
      return {};
    }

    const jsonResponse = await response.json();

    if (!response.ok) {
      throw new BadRequestException(response.status, jsonResponse.message, {
        ...jsonResponse,
        loginURL: response.url,
      });
    }

    return jsonResponse;
  } catch (e) {
    if (e instanceof BadRequestException) {
      throw e;
    }

    throw new RuntimeException(response.status, (e as Error).message, {
      loginURL: response.url,
    });
  }
};

export const doRequest = async (
  path: RequestInfo,
  requestOptions: RequestInit
): Promise<any> => {
  let response = null;
  try {
    response = await fetch(path, requestOptions);

    return handleResponse(response);
  } catch (e) {
    if (e instanceof BadRequestException) {
      throw e;
    } else {
      // handle CORS in case of whitelist IP or not configured platform
      throw new CorsRequestException('AccessDenied');
    }
  }
};

export const buildRequestInput = (
  method: string,
  body?: object
): RequestInit => {
  const requestOptions: RequestInit = {
    mode: 'cors',
    credentials: 'include',
    method,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (body) {
    requestOptions.body = JSON.stringify(body);
  }

  return requestOptions;
};
