import { Logo } from '@doctaridev/io.planer.library.npm.design_system_v2';
import {
  Button,
  Grid,
  Link,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { MdLanguage } from '@react-icons/all-files/md/MdLanguage';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  FALLBACK_LANGUAGE,
  LANGUAGE_LABELS,
  LANGUAGE_OPTIONS,
} from '../../i18n';

type HeaderProps = {};

const Header: FunctionComponent<HeaderProps> = () => {
  const { i18n, t } = useTranslation();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const browserLng = LANGUAGE_OPTIONS.filter(
    (lng) => lng === navigator.language.split('-')[0]
  )[0];
  const open = Boolean(anchorEl);
  const showChangeLanguageTooltip = Boolean(browserLng !== i18n.language);

  const changeLanguageTooltip = () =>
    t('login.changeLanguage', { lng: browserLng || 'en' });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (newLang: string) => {
    setAnchorEl(null);
    if (!newLang || i18n.language === newLang) {
      return;
    }
    const [, route] = pathname.split(`/${i18n.language}/`);
    navigate(`${newLang}/${route}${search}`);
    i18n.changeLanguage(newLang);
  };

  const getLink = () => {
    if (i18n.language === 'en') {
      return 'https://planerio.com/';
    }
    return 'https://planerio.de/';
  };

  return (
    <Grid
      container
      spacing="0"
      direction="row"
      justifyContent="space-around"
      sx={{
        minHeight: '5vh',
      }}
    >
      <Grid
        item
        xs={8}
        sm={9}
        md={10}
        alignSelf="center"
        sx={{
          marginTop: '1vh',
        }}
      >
        <Link href={getLink()} target="_blank" underline="hover">
          <Logo />
        </Link>
      </Grid>
      <Grid item alignSelf="center" xs={4} sm={3} md={2}>
        <Tooltip
          title={changeLanguageTooltip()}
          arrow
          placement="top"
          open={!open && showChangeLanguageTooltip}
        >
          <Button
            id="changeLanguageButton"
            aria-controls="changeLanguageMenu"
            color="inherit"
            variant="contained"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            startIcon={<MdLanguage />}
          >
            {LANGUAGE_LABELS[i18n.resolvedLanguage ?? FALLBACK_LANGUAGE]}
          </Button>
        </Tooltip>
        <Menu
          id="changeLanguageMenu"
          aria-labelledby="changeLanguageButton"
          anchorEl={anchorEl}
          open={open}
          onClose={() => handleClose(i18n.language)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {LANGUAGE_OPTIONS.filter((lng) => lng !== i18n.language).map(
            (langItem) => (
              <MenuItem
                id={`changeLanguageMenuItem_${langItem}`}
                key={langItem}
                onClick={() => handleClose(langItem)}
              >
                <Typography
                  id={`changeLanguageMenuItemTypography_${langItem}`}
                  variant="inherit"
                >
                  {LANGUAGE_LABELS[langItem]}
                </Typography>
              </MenuItem>
            )
          )}
        </Menu>
      </Grid>
    </Grid>
  );
};

export default Header;
