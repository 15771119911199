import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import SelectDomainForm from '../../../../components/select-domain-form';
import { showMessage } from '../../../../features/message/message-slice';
import { setPageTitle } from '../../../../features/page-title/page-title-slice';
import {
  signInCentralAuth,
  signInLegacy,
} from '../../../../features/sign-in/services/direct-auth';
import {
  setSelectedInstance,
  updateSignInState,
} from '../../../../features/sign-in/sign-in-slice';
import { InstanceEnvironment } from '../../../../features/sign-in/types/InstanceEnvironment';
import { SignInStepType } from '../../../../features/sign-in/types/SignInStepType';
import { logError } from '../../../../helper/LoggerHelper';
import { handleLoginResponse } from '../../../../helper/LoginHelper';
import { getMessagesFromResponseError } from '../../../../helper/request-helper';

type SelectDomainProps = {};

const SelectDomain: FunctionComponent<SelectDomainProps> = () => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const { username, password, suggestedInstances } = useAppSelector(
    (state: RootState) => state.signInStore
  );
  const [submitIsLoading, setSubmitIsLoading] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setPageTitle({ title: 'selectDomain.pageTitle' }));

    return () => {};
  }, []);

  const onInstanceSelected = async (instance: InstanceEnvironment) => {
    dispatch(setSelectedInstance(instance));
    setSubmitIsLoading(true);
    try {
      if (username && password && instance) {
        console.info(
          'Send request to the selected instance with username and password to login user'
        );

        if (instance.useCentralAuth) {
          const data = await signInCentralAuth(
            username,
            password,
            instance,
            i18n.language
          );
          handleLoginResponse(data, dispatch);
        } else {
          const data = await signInLegacy(
            username,
            password,
            instance,
            i18n.language
          );

          if (data.success) {
            if (data.terms) {
              dispatch(updateSignInState(SignInStepType.SignedIn));
            } else {
              dispatch(updateSignInState(SignInStepType.AcceptTerms));
            }
          } else {
            dispatch(updateSignInState(SignInStepType.CheckUsernamePassword));
            dispatch(
              showMessage({
                type: 'error',
                message: 'login.loginError',
              })
            );
          }
        }
      } else {
        dispatch(updateSignInState(SignInStepType.CheckUsernamePassword));
        dispatch(
          showMessage({
            type: 'error',
            message: 'login.loginError',
          })
        );
      }
    } catch (e) {
      dispatch(updateSignInState(SignInStepType.CheckUsernamePassword));

      logError(e);
      const errorMessages: string[] = getMessagesFromResponseError(e);

      dispatch(
        showMessage({
          type: 'error',
          messages: errorMessages,
        })
      );
    }
  };

  return (
    <SelectDomainForm
      suggestedInstances={suggestedInstances}
      onInstanceSelected={onInstanceSelected}
      processingFormData={submitIsLoading}
    />
  );
};

export default SelectDomain;
