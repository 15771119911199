import { AlertColor } from '@mui/material/Alert/Alert';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type MessagePayloadAction = {
  message?: string;
  messages?: string[];
  type: AlertColor;
};

export type MessageState = {
  messages: string[];
  open: boolean;
  type: AlertColor;
};

const initialState: MessageState = {
  open: false,
  messages: [],
  type: 'info',
};
const messageSlice = createSlice({
  name: 'signIn',
  initialState,
  reducers: {
    showMessage(state, action: PayloadAction<MessagePayloadAction>) {
      state.type = action.payload.type;
      if (action.payload.message) {
        state.messages = [action.payload.message];
      }
      if (Array.isArray(action.payload.messages)) {
        state.messages = action.payload.messages;
      }
      state.open = true;
    },
    clearMessage(state) {
      state.messages = [];
      state.open = false;
    },
  },
});

export const { showMessage, clearMessage } = messageSlice.actions;
export default messageSlice.reducer;
