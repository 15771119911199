import { Box, Container, Grid } from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import SelectDomainForm from '../../components/select-domain-form';
import Message from '../../features/message/message';
import {
  clearMessage,
  showMessage,
} from '../../features/message/message-slice';
import PageTitle from '../../features/page-title/page-title';
import { setPageTitle } from '../../features/page-title/page-title-slice';
import { useCheckUserByEmailMutation } from '../../features/sign-in/sign-in-api-slice';
import { InstanceEnvironment } from '../../features/sign-in/types/InstanceEnvironment';
import { getInstanceError } from '../../helper/InstanceEnvironmentHelper';
import { logError } from '../../helper/LoggerHelper';
import { useOriginInstance } from '../../hooks';
import UsernameForm from './components/username-form/username-form';
import { forgotPasswordRequest } from './services/forgot-password-api-service';

type ForgotPasswordProps = {};

const ForgotPassword: FunctionComponent<ForgotPasswordProps> = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [email, setEmail] = useState<string>('');
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(false);
  const [selectInstanceMode, setSelectInstanceMode] = useState<boolean>(false);
  const [availableInstances, setAvailableInstances] = useState<
    Array<InstanceEnvironment>
  >([]);
  const [checkUserByEmail, { isLoading }] = useCheckUserByEmailMutation();
  const originInstance = useOriginInstance();

  useEffect(() => {
    dispatch(setPageTitle({ title: 'forgotPassword.pageTitle' }));

    return () => {
      dispatch(clearMessage());
    };
  }, []);

  const defineSuggestedInstances = async (
    requestedEmail: string
  ): Promise<Array<InstanceEnvironment>> => {
    const suggestedInstances: Array<InstanceEnvironment> =
      await checkUserByEmail({
        email: requestedEmail,
      }).unwrap();

    setAvailableInstances(suggestedInstances);

    return suggestedInstances;
  };

  const sendResetPassword = async (
    requestedEmail: string,
    selectedInstance: InstanceEnvironment
  ): Promise<boolean> => {
    const error = getInstanceError(selectedInstance);

    if (error) {
      dispatch(
        showMessage({
          type: 'error',
          message: error,
        })
      );

      return false;
    }

    try {
      setDisabledSubmit(true);
      await forgotPasswordRequest(
        requestedEmail,
        selectedInstance,
        i18n.language
      );
      dispatch(
        showMessage({
          type: 'success',
          message: t('forgotPassword.text1'),
        })
      );
      return true;
    } catch (e) {
      logError(e);
      const errorMessage =
        (e as Error).message === 'AccessDenied'
          ? 'login.notOnWhitelist'
          : (e as Error).message || 'forgotPassword.error';

      dispatch(
        showMessage({
          type: 'error',
          message: errorMessage,
        })
      );
      setDisabledSubmit(false);
      return false;
    }
  };

  const onInstanceSelected = async (instance: InstanceEnvironment) => {
    await sendResetPassword(email, instance);
  };

  const onEmailDefined = async (requestedEmail: string) => {
    const suggestedInstances = await defineSuggestedInstances(requestedEmail);

    const origin = suggestedInstances.filter(
      (instance) => instance.host === originInstance
    );
    setEmail(requestedEmail);

    if (suggestedInstances.length === 0) {
      dispatch(
        showMessage({
          type: 'success',
          message: t('forgotPassword.messages.text2'),
        })
      );
    } else if (suggestedInstances.length === 1) {
      await sendResetPassword(requestedEmail, suggestedInstances[0]);
    } else if (suggestedInstances.length > 1 && origin.length === 1) {
      await sendResetPassword(requestedEmail, origin[0]);
    } else {
      setSelectInstanceMode(true);
    }
  };

  return (
    <Box
      component="main"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        minHeight: '100%',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Container maxWidth="xs">
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              textAlign: 'center',
              paddingBottom: '5vh',
            }}
          >
            <PageTitle />
          </Grid>
          <Grid item xs={12}>
            <Message />
            {!selectInstanceMode && (
              <UsernameForm
                processingFormData={isLoading}
                onUsernameDefined={onEmailDefined}
                forgotPassword
                disabledSubmit={disabledSubmit}
              />
            )}
            {selectInstanceMode && (
              <SelectDomainForm
                processingFormData={isLoading}
                suggestedInstances={availableInstances}
                onInstanceSelected={onInstanceSelected}
                disabledSubmit={disabledSubmit}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default ForgotPassword;
