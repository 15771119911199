import { PLANERIO_TOP_LEVEL_DOMAIN } from '../../../app/constants';
import { buildRequestInput, doRequest } from '../../../helper/request-helper';
import { ChallengeParams } from '../types/ChallengeParams';
import { InstanceEnvironment } from '../types/InstanceEnvironment';

export interface SignInResponseCentralAuth {
  challenge: ChallengeParams;
}
export interface SSOSettings {
  idpUrl: string;
}

export interface SSOState {
  lng: string;
  email: string;
}

interface SignInResponseLegacy {
  success: boolean;
  terms: boolean; // if it's true it means that terms should be accepted
}

export const signInCentralAuth = async (
  username: string,
  password: string,
  selectedInstance: InstanceEnvironment,
  language: string
): Promise<SignInResponseCentralAuth> => {
  const requestOptions = buildRequestInput('POST', {
    username,
    password,
  });

  return doRequest(
    `//${selectedInstance.host}/planerio/central-auth/api/public/login?lng=${language}`,
    requestOptions
  );
};

export const signInLegacy = async (
  username: string,
  password: string,
  selectedInstance: InstanceEnvironment,
  language: string
): Promise<SignInResponseLegacy> => {
  const requestOptions = buildRequestInput('POST', {
    _username: username,
    _password: password,
  });

  return doRequest(
    `//${selectedInstance.host}/${language}/login_check`,
    requestOptions
  );
};

export const acceptTermsCentralAuth = (
  selectedInstance: InstanceEnvironment,
  language: string,
  challengeParams: ChallengeParams
): Promise<any> => {
  const requestOptions = buildRequestInput('POST', challengeParams);

  return doRequest(
    `//${selectedInstance.host}/planerio/central-auth/api/public/challenges/accept-terms?lng=${language}`,
    requestOptions
  );
};

export const setupSoftwareToken = async (
  selectedInstance: InstanceEnvironment,
  language: string,
  verificationCode: string,
  sessionId: string
): Promise<void> => {
  const requestOptions = buildRequestInput('POST', {
    code: verificationCode,
    sessionId,
  });

  await doRequest(
    `//${selectedInstance.host}/planerio/central-auth/api/public/challenges/setup-software-token?lng=${language}`,
    requestOptions
  );
};

export const respondSoftwareTokenMfa = (
  selectedInstance: InstanceEnvironment,
  username: string,
  language: string,
  userCode: string,
  session: string
): Promise<SignInResponseCentralAuth> => {
  const requestOptions = buildRequestInput('POST', {
    preferredUsername: username,
    userCode,
    session,
  });
  const { host } = selectedInstance;

  return doRequest(
    `//${host}/planerio/central-auth/api/public/challenges/validate-software-token-mfa?lng=${language}`,
    requestOptions
  );
};

export const acceptTermsLegacy = (
  username: string,
  selectedInstance: InstanceEnvironment
): Promise<any> => {
  const requestOptions = buildRequestInput('POST', {
    _username: username,
  });

  return doRequest(
    `//${selectedInstance.host}/api/v1/accept_terms`,
    requestOptions
  );
};

export const directCompleteTemporaryPasswordRequest = (
  targetInstanceHostname: string,
  newPassword: string
): Promise<{ isTermsAccepted: boolean }> => {
  const requestOptions: RequestInit = buildRequestInput('POST', {
    plainPassword: newPassword,
  });
  return doRequest(
    `//${targetInstanceHostname}/api/v1/user/complete-temporary-password`,
    requestOptions
  );
};

const validateHostname = (targetInstanceHostname: string) => {
  if (
    !targetInstanceHostname.endsWith(`.${PLANERIO_TOP_LEVEL_DOMAIN}`) &&
    targetInstanceHostname !== PLANERIO_TOP_LEVEL_DOMAIN
  ) {
    throw new Error('Hostname rejected');
  }
};

export const directRegisterConfirmRequest = (
  targetInstanceHostname: string,
  token: string,
  password: string,
  acceptConditions: boolean
): Promise<any> => {
  validateHostname(targetInstanceHostname);

  let formData: any = [];
  formData.push(
    `omedoo_medschedule_confirm[new][first]=${encodeURIComponent(password)}`
  );
  formData.push(
    `omedoo_medschedule_confirm[new][second]=${encodeURIComponent(password)}`
  );
  formData.push(
    `omedoo_medschedule_confirm[_token]=${encodeURIComponent(token)}`
  );
  formData.push(`acceptConditions=${acceptConditions}`);
  formData = formData.join('&');

  const requestOptions: RequestInit = {
    mode: 'cors',
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: formData,
  };

  return doRequest(
    `https://${targetInstanceHostname}/user/register/confirm-password/${token}`,
    requestOptions
  );
};

export const directResetPasswordRequest = (
  targetInstanceHostname: string,
  token: string,
  password: string
): Promise<any> => {
  validateHostname(targetInstanceHostname);

  let formData: any = [];
  formData.push(
    `fos_user_resetting_form[plainPassword][first]=${encodeURIComponent(
      password
    )}`
  );
  formData.push(
    `fos_user_resetting_form[plainPassword][second]=${encodeURIComponent(
      password
    )}`
  );
  formData.push(`fos_user_resetting_form[_token]=${encodeURIComponent(token)}`);
  formData = formData.join('&');

  const requestOptions: RequestInit = {
    mode: 'cors',
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: formData,
  };

  return doRequest(
    `https://${targetInstanceHostname}/user/resetting/change-password/${token}`,
    requestOptions
  );
};

export const confirmNewPasswordChallengeCentralAuth = (
  payload: ChallengeParams & {
    password: string;
  },
  selectedInstance: InstanceEnvironment,
  lng: string
): Promise<SignInResponseCentralAuth> => {
  const requestOptions = buildRequestInput('POST', payload);

  return doRequest(
    `//${selectedInstance.host}/planerio/central-auth/api/public/challenges/new-password?lng=${lng}`,
    requestOptions
  );
};

export const confirmForgotPasswordCentralAuth = (
  payload: {
    email: string;
    confirmationCode: string;
    password: string;
  },
  selectedInstance: InstanceEnvironment
): Promise<void> => {
  const requestOptions = buildRequestInput('POST', payload);

  return doRequest(
    `//${selectedInstance.host}/planerio/central-auth/api/public/forgot-password/confirm`,
    requestOptions
  );
};

export const getSSOSettingsCentralAuth = async (
  email: string,
  selectedInstance: InstanceEnvironment,
  language: string
): Promise<SSOSettings> => {
  const requestOptions = buildRequestInput('POST', { email });

  return doRequest(
    `//${selectedInstance.host}/planerio/central-auth/api/public/sso/settings?lng=${language}`,
    requestOptions
  );
};

export const loginUsingSSOCodeCentralAuth = async (
  host: string,
  code: string,
  state: string,
  lng: string
): Promise<SignInResponseCentralAuth> => {
  const requestOptions = buildRequestInput('POST', { state, code });

  return doRequest(
    `//${host}/planerio/central-auth/api/public/sso/login?lng=${lng}`,
    requestOptions
  );
};
