import { Box, Container, Link, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

const NotFoundPage: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Box
      component="main"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        minHeight: '100%',
      }}
    >
      <Container maxWidth="md">
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography align="center" color="textPrimary" variant="h1">
            {t('pageNotFound.title')}
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle2">
            {t('pageNotFound.subtitle')}
          </Typography>
          <Link href="/">{t('pageNotFound.backButton')}</Link>
        </Box>
      </Container>
    </Box>
  );
};

export default NotFoundPage;
