import { mdTheme as theme } from '@doctaridev/io.planer.library.npm.design_system_v2';
import {
  Container,
  CssBaseline,
  Divider,
  Grid,
  Stack,
  ThemeProvider,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createRoutesFromChildren,
  matchRoutes,
  Navigate,
  useLocation,
  useNavigationType,
  useRoutes,
} from 'react-router-dom';
import Footer from './components/footer';
import Header from './components/header';
import { LanguageDetector } from './components/LanguageDetector';
import { MaintenanceScreen } from './components/maintenance-screen/MaintenanceScreen';
import {
  getMillisecondsUntilMaintenanceEnd,
  isMaintenanceTime,
} from './helper/MaintenanceScreenHelper';
import { addRequestId } from './helper/sentry/BeforeFetchBreadcrumb';
import ForgotPassword from './routes/forgot-password/forgot-password';
import NotFoundPage from './routes/not-found-page';
import { RecoverPassword } from './routes/recover-password';
import { RegisterConfirm } from './routes/register-confirm/register-confirm';
import { ResetPassword } from './routes/reset-password/reset-password';
import SignInPage from './routes/sign-in/sign-in.page';
import { CallbackPage } from './routes/sso/CallbackPage';
import { SignIn } from './routes/sso/SignIn';
import { Ad } from './teasers/Ad';

Sentry.init({
  attachStacktrace: true,
  autoSessionTracking: false,
  debug: process.env.NODE_ENV === 'development',
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  maxBreadcrumbs: 100,
  release: process.env.REACT_APP_SENTRY_RELEASE || 'local',
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  sampleRate: 1,
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category === 'fetch') {
      addRequestId(breadcrumb, hint);
    }

    return breadcrumb;
  },
});

const useSentryRoutes = Sentry.wrapUseRoutes(useRoutes);

type AppPropsType = {};

const App: FunctionComponent<AppPropsType> = () => {
  const { i18n } = useTranslation();
  const { search } = useLocation();
  const [showMaintenancePage, setShowMaintenancePage] = useState(false);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (isMaintenanceTime()) {
      setShowMaintenancePage(true);
      timer = setTimeout(() => {
        setShowMaintenancePage(false);
      }, getMillisecondsUntilMaintenanceEnd());
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  const element = useSentryRoutes([
    {
      path: '',
      element: <Navigate to={{ pathname: i18n.language, search }} />,
    },
    {
      path: 'sso-response',
      element: (
        <CallbackPage>
          <SignInPage />
        </CallbackPage>
      ),
    },
    {
      path: ':locale',
      element: <LanguageDetector />,
      children: [
        {
          path: '',
          element: <Navigate to={{ pathname: 'login', search }} />,
        },
        {
          path: 'login',
          element: <SignInPage />,
        },
        {
          path: 'sso',
          element: <SignIn />,
        },
        {
          path: 'recover-password',
          element: (
            <RecoverPassword>
              <SignInPage isRecoverPassword />
            </RecoverPassword>
          ),
        },
        {
          path: 'forgot-password',
          element: <ForgotPassword />,
        },
        {
          path: 'reset-password/:instanceHostName/:token',
          element: (
            <ResetPassword>
              <SignInPage isResetPassword />
            </ResetPassword>
          ),
        },
        {
          path: 'register-confirm/:instanceHostName/:token',
          element: (
            <RegisterConfirm>
              <SignInPage isRegisterConfirm />
            </RegisterConfirm>
          ),
        },
      ],
    },
    {
      path: '*',
      element: <NotFoundPage />,
    },
  ]);

  const showTeaser = false;
  const widthWithTeaser = '960px';

  return (
    <React.StrictMode>
      <CssBaseline enableColorScheme />
      <ThemeProvider theme={theme}>
        <Stack
          divider={<Divider orientation="horizontal" flexItem />}
          component="main"
          sx={{ height: '100vh' }}
          spacing={0}
          direction="column"
          alignItems="stretch"
        >
          <Container>
            <Header />
          </Container>
          <Grid
            container
            flex="1 0 auto"
            sx={{
              backgroundColor: '#f5f5f5',
            }}
            justifyContent="center"
          >
            <Grid
              item
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexGrow: 1,
                minHeight: '100%',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
              lg={showTeaser ? 8 : 4}
              md={showTeaser ? 12 : 6}
            >
              <Grid
                container
                justifyContent="center"
                sx={{
                  background: '#FFFFFF',
                  borderRadius: '8px',
                  boxShadow:
                    '0px 3px 5px -1px rgba(0, 0, 0, 0.2), ' +
                    '0px 5px 8px rgba(0, 0, 0, 0.14), ' +
                    '0px 1px 14px rgba(0, 0, 0, 0.12)',
                  mt: 1,
                  mb: 1,
                  maxWidth: showTeaser ? widthWithTeaser : 'none',
                }}
              >
                <Grid
                  item
                  md={showTeaser ? 6 : 12}
                  sm={12}
                  sx={{
                    paddingTop: '70px',
                    paddingBottom: { md: '70px', lg: '70px' },
                  }}
                >
                  {showMaintenancePage ? <MaintenanceScreen /> : element}
                </Grid>
                {showTeaser && (
                  <Ad
                    url="https://planerio.de/em-tippspiel/"
                    imgUrl="/em-tippspiel.png"
                    clickableLinkAdditionalStyle={{
                      bottom: '40px',
                      height: '75px',
                      top: undefined,
                      width: undefined,
                      right: '50px',
                      left: '50px',
                    }}
                    adAdditionalSxProps={{
                      backgroundPositionY: 'bottom',
                      flexBasis: '100%',
                    }}
                    hideIfBreakpointIsLowerThan="sm"
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Container>
            <Footer />
          </Container>
        </Stack>
      </ThemeProvider>
    </React.StrictMode>
  );
};

export default App;
