import { configureStore } from '@reduxjs/toolkit';
import messageReducer from '../features/message/message-slice';
import pageTitleReducer from '../features/page-title/page-title-slice';
import { apiSlice } from '../features/sign-in/sign-in-api-slice';
import signInSliceReducer from '../features/sign-in/sign-in-slice';

export const store = configureStore({
  reducer: {
    messages: messageReducer,
    pageTitle: pageTitleReducer,
    signInStore: signInSliceReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
