import { Button, ButtonProps } from '@mui/material';
import { FunctionComponent, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { clearMessage } from '../../features/message/message-slice';

type Props = {
  disabled?: boolean;
  originInstance?: string;
};

const ForgotPasswordButton: FunctionComponent<ButtonProps> = ({
  disabled,
  originInstance,
}: Props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onClickForgetPassword: MouseEventHandler<HTMLButtonElement> = (
    e
  ): void => {
    e.preventDefault();

    if (disabled) {
      return;
    }

    dispatch(clearMessage());

    if (originInstance) {
      navigate(
        `/${i18n.language}/forgot-password?originInstance=${originInstance}`,
        { replace: true }
      );
    } else {
      navigate(`/${i18n.language}/forgot-password`, { replace: true });
    }
  };

  return (
    <Button
      id="forgotPasswordButton"
      disabled={disabled}
      variant="text"
      onClick={onClickForgetPassword}
    >
      {t('forgotPasswordButton.label')}
    </Button>
  );
};

ForgotPasswordButton.defaultProps = {
  disabled: true,
};

export default ForgotPasswordButton;
