export function logError(event: Error | any) {
  console.error(event);
  if (window.onerror) {
    let errorEvent = event;
    if (!(event instanceof Error)) {
      errorEvent = new Error(event.message || event.error || event);
    }
    errorEvent.requestedHost = window.location.hostname;
    errorEvent.body = {
      ...errorEvent.body,
      requestedHost: window.location.hostname,
    };

    window.onerror(
      errorEvent.message,
      window.location.pathname,
      0,
      0,
      errorEvent
    );
  }
}
