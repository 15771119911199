import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SetConfiguredInstancesAction } from './slice-payload-actions/SetConfiguredInstancesAction';
import { ChallengeParams } from './types/ChallengeParams';
import { InstanceEnvironment } from './types/InstanceEnvironment';
import { SignInState } from './types/SignInState';
import { SignInStepType } from './types/SignInStepType';

const initialState: SignInState = {
  signInState: SignInStepType.CheckUsernamePassword,
  suggestedInstances: [],
  selectedInstance: null,
  username: null,
  password: null,
  challengeParams: null,
};

const signInSlice = createSlice({
  name: 'sign-in-slice',
  initialState,
  reducers: {
    setSuggestedInstances: {
      prepare: (payload: SetConfiguredInstancesAction) => {
        const selectedInstance =
          payload.suggestedInstances.length === 1
            ? payload.suggestedInstances[0]
            : undefined;

        return {
          payload: {
            ...payload,
            selectedInstance,
          },
        };
      },
      reducer: (state, action: PayloadAction<SetConfiguredInstancesAction>) => {
        state.username = action.payload.username;
        state.password = action.payload.password;
        state.suggestedInstances = action.payload.suggestedInstances;
        if (action.payload.selectedInstance) {
          state.selectedInstance = action.payload.selectedInstance;
        } else {
          state.signInState = SignInStepType.ChoiceDomain;
        }
      },
    },
    setSelectedInstance(state, action: PayloadAction<InstanceEnvironment>) {
      state.selectedInstance = action.payload;
    },
    acceptTermsAndConditions(state) {
      state.signInState = SignInStepType.SignedIn;
      if (!state.selectedInstance) {
        throw new Error('Value for selectedInstance should be defined');
      }
    },
    updateSignInState(state, action: PayloadAction<SignInStepType>) {
      state.signInState = action.payload;
    },
    setUsername(state, action: PayloadAction<string>) {
      state.username = action.payload;
    },
    setPassword(state, action: PayloadAction<string>) {
      state.password = action.payload;
    },
    setChallengeParams(state, action: PayloadAction<ChallengeParams>) {
      state.challengeParams = action.payload;
    },
    resetSignInProcess: () => initialState,
  },
});

export const {
  setSuggestedInstances,
  setSelectedInstance,
  setUsername,
  setPassword,
  setChallengeParams,
  acceptTermsAndConditions,
  updateSignInState,
  resetSignInProcess,
} = signInSlice.actions;
export default signInSlice.reducer;
