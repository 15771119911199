import { FunctionComponent, useEffect } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { updateSignInState } from '../../features/sign-in/sign-in-slice';
import { SignInStepType } from '../../features/sign-in/types/SignInStepType';

type RecoverPasswordProps = {
  children: any;
};

export const RecoverPassword: FunctionComponent<RecoverPasswordProps> = (
  props
) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(updateSignInState(SignInStepType.RecoverPassword));
  }, []);

  return props.children;
};
