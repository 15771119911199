export enum SignInStepType {
  Initialization = 'initialization',
  CheckUsernamePassword = 'checkUsernamePassword',
  InitialState = 'initialState',
  ChoiceDomain = 'choiceDomain',
  ForgotPassword = 'forgot-password',
  ResetPassword = 'reset-password',
  RegisterConfirm = 'register-confirm',
  RecoverPassword = 'recover-password',
  AcceptTerms = 'accept-terms',
  SignedIn = 'signedIn',
  NewPasswordRequired = 'newPasswordRequired',
  MfaSetup = 'MFA_Setup',
  SoftwareTokenMfa = 'SoftwareTokenMfa',
  LoginWithSSO = 'LoginWithSSO',
}
