import { FunctionComponent, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { updateSignInState } from '../../features/sign-in/sign-in-slice';
import { SignInStepType } from '../../features/sign-in/types/SignInStepType';

type RegisterConfirmProps = {
  children: any;
};

export const RegisterConfirm: FunctionComponent<RegisterConfirmProps> = ({
  children,
}) => {
  const { signInState } = useAppSelector(
    (state: RootState) => state.signInStore
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(updateSignInState(SignInStepType.RegisterConfirm));
  }, []);

  return (
    (signInState === SignInStepType.RegisterConfirm ||
      signInState === SignInStepType.CheckUsernamePassword ||
      signInState === SignInStepType.AcceptTerms ||
      signInState === SignInStepType.SignedIn) &&
    children
  );
};
