import type { InstanceEnvironment } from '../../../features/sign-in/types/InstanceEnvironment';
import { buildRequestInput, doRequest } from '../../../helper/request-helper';

export const forgotPasswordRequest = (
  email: string,
  selectedInstance: InstanceEnvironment,
  language: string
): Promise<any> => {
  const requestOptions = buildRequestInput('POST', {
    email,
  });

  const requestUrl = selectedInstance.useCentralAuth
    ? `//${selectedInstance.host}/planerio/central-auth/api/public/forgot-password/request?locale=${language}`
    : `//${selectedInstance.host}/user/resetting/requestPassword?locale=${language}`;

  return doRequest(requestUrl, requestOptions);
};
