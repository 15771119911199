export const PLANERIO_TOP_LEVEL_DOMAIN = window.location.hostname.replace(
  'login.',
  ''
);

export const MAINTENANCE_MODE = {
  // UTC time in format ISO 8601 when maintenance mode starts
  // @see https://day.js.org/docs/en/parse/string
  UTC_START: '2024-04-15 21:00:00',
  DURATION_IN_MINUTES: 30,
};
