import { Alert, Collapse } from '@mui/material';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { clearMessage } from './message-slice';

interface MessageProps {}

const Message: FunctionComponent<MessageProps> = () => {
  const { t } = useTranslation();
  const { messages, open, type } = useAppSelector(
    (state: RootState) => state.messages
  );
  const dispatch = useDispatch();

  const handleChangeAlert = () => {
    dispatch(clearMessage());
  };

  const renderMessagesList = () => (
    <ul>
      {messages.map((message: string) => (
        <li key={message}>{t(message)}</li>
      ))}
    </ul>
  );

  return (
    <Collapse in={open}>
      <Alert
        onClose={handleChangeAlert}
        icon={false}
        severity={type}
        className="MessageAlert"
        data-testid="alert-message"
      >
        {messages.length === 1 && t(messages[0])}
        {messages.length > 1 && renderMessagesList()}
      </Alert>
    </Collapse>
  );
};

export default Message;
