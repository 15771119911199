import { GeneralError } from './GeneralError';
import { StatusCode } from './StatusCode';

export class RuntimeException extends GeneralError {
  public readonly body: Record<string, unknown> | undefined;

  public readonly code: StatusCode;

  public constructor(
    code: StatusCode,
    message: string,
    body?: Record<string, unknown> | undefined
  ) {
    super(message);

    this.body = body;
    this.code = code;
  }
}
