import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getMaintenanceTimeInLocalTz } from '../../helper/MaintenanceScreenHelper';

const { startDate, endDate } = getMaintenanceTimeInLocalTz();

export const MaintenanceScreen = () => {
  const { t } = useTranslation();

  return (
    <Box
      component="main"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        minHeight: '100%',
      }}
    >
      <Container maxWidth="md">
        <Box
          className="pic"
          sx={{
            background: 'url(/img/maintenance.jpeg) center center no-repeat',
            backgroundSize: 'contain',
            minHeight: '524px',
          }}
        >
          {' '}
        </Box>
        <Box
          sx={{
            alignItems: 'left',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography color="textPrimary" variant="subtitle2">
            {t('maintenance.greeting')}
          </Typography>
          <Typography color="textPrimary" variant="subtitle2">
            {t('maintenance.durationMsg', {
              date: startDate.format('DD.MM.YYYY'),
              startTime: startDate.format('HH:mm'),
              endTime: endDate.format('HH:mm'),
            })}
          </Typography>
          <Typography color="textPrimary" variant="subtitle2">
            {t('maintenance.message')}
          </Typography>
          <Typography color="textPrimary" variant="subtitle2">
            {t('maintenance.signature')}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};
