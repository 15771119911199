import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export const LANGUAGE_LABELS: { [key: string]: string } = {
  de: 'Deutsch',
  en: 'English',
  fr: 'Français',
  it: 'Italiano',
  es: 'Español',
  nl: 'Nederlands',
  pl: 'Polski',
};
export const LANGUAGE_OPTIONS = Object.keys(LANGUAGE_LABELS);
export const FALLBACK_LANGUAGE = 'de';

const languageDetector = new LanguageDetector(null, {
  lookupFromPathIndex: 0,
  order: ['customPath', 'localStorage'],
});

languageDetector.addDetector({
  name: 'customPath',
  lookup() {
    if (typeof window !== 'undefined') {
      const path = window.location.pathname;

      return LANGUAGE_OPTIONS.filter((language) =>
        path.includes(`/${language}/`)
      );
    }
    return [];
  },
});

i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: FALLBACK_LANGUAGE,
    preload: LANGUAGE_OPTIONS,
    cleanCode: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
