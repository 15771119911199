import { ComponentException } from '../errors/ComponentException';
import { getSSOSettingsCentralAuth } from '../features/sign-in/services/direct-auth';
import { InstanceEnvironment } from '../features/sign-in/types/InstanceEnvironment';
import { getInstanceError } from './InstanceEnvironmentHelper';

export const getIdentityProviderURL = async (
  instance: InstanceEnvironment,
  email: string,
  lng: string
) => {
  const error = getInstanceError(instance);

  if (error) {
    throw new ComponentException(error);
  }

  if (!instance.useCentralAuth) {
    throw new ComponentException('CentralAuth is not enabled.');
  }

  const data = await getSSOSettingsCentralAuth(email, instance, lng);

  return data.idpUrl;
};
