import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { MAINTENANCE_MODE } from '../app/constants';
import { logError } from './LoggerHelper';

dayjs.extend(utc);

export const isMaintenanceTime = (): boolean => {
  const maintenanceStartTime = dayjs.utc(MAINTENANCE_MODE.UTC_START);
  if (!maintenanceStartTime.isValid()) {
    logError(`Invalid maintenance start time: ${MAINTENANCE_MODE.UTC_START}`);
    return false;
  }

  const maintenanceEndTime = maintenanceStartTime.add(
    MAINTENANCE_MODE.DURATION_IN_MINUTES,
    'minutes'
  );
  if (!maintenanceEndTime.isValid()) {
    logError(`Invalid maintenance end time: ${maintenanceEndTime}`);
    return false;
  }

  const currentTime = dayjs.utc();
  return (
    currentTime.isAfter(maintenanceStartTime) &&
    currentTime.isBefore(maintenanceEndTime)
  );
};

export const getMaintenanceTimeInLocalTz = () => ({
  startDate: dayjs.utc(MAINTENANCE_MODE.UTC_START).local(),
  endDate: dayjs
    .utc(MAINTENANCE_MODE.UTC_START)
    .add(MAINTENANCE_MODE.DURATION_IN_MINUTES, 'minutes')
    .local(),
});

export const getMillisecondsUntilMaintenanceEnd = () => {
  const currentTime = dayjs.utc();
  const timeDiff = dayjs
    .utc(MAINTENANCE_MODE.UTC_START)
    .add(MAINTENANCE_MODE.DURATION_IN_MINUTES, 'minutes')
    .diff(currentTime);
  return timeDiff > 0 ? timeDiff : 0;
};
