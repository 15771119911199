import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../app/hooks';
import ChangePasswordForm from '../../../../components/change-password-form/change-password-form';
import { showMessage } from '../../../../features/message/message-slice';
import { setPageTitle } from '../../../../features/page-title/page-title-slice';
import { directRegisterConfirmRequest } from '../../../../features/sign-in/services/direct-auth';
import { logError } from '../../../../helper/LoggerHelper';
import { getMessagesFromResponseError } from '../../../../helper/request-helper';

const RegisterConfirm: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { instanceHostName, token } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setPageTitle({ title: 'registerConfirm.pageTitle' }));

    return () => {};
  }, []);

  const onSubmitHandler = async (
    password: string,
    acceptConditions: boolean
  ) => {
    try {
      setIsLoading(true);

      if (instanceHostName && token) {
        // Register Confirm
        await directRegisterConfirmRequest(
          instanceHostName,
          token,
          password,
          acceptConditions
        )
          .then(() => {
            dispatch(
              showMessage({
                type: 'success',
                message: t('registerConfirm.success'),
              })
            );
            setIsSuccess(true);
          })
          .catch(() => {
            dispatch(
              showMessage({
                type: 'error',
                message: t('registerConfirm.error'),
              })
            );
          });
        setIsLoading(false);
      } else {
        setIsLoading(false);
        dispatch(
          showMessage({
            type: 'error',
            message: t('registerConfirm.missingParams'),
          })
        );
      }
    } catch (e) {
      setIsLoading(false);
      logError(e);
      const errorMessages: string[] = getMessagesFromResponseError(e);

      dispatch(
        showMessage({
          type: 'error',
          messages: errorMessages,
        })
      );
    }
  };

  return isSuccess ? (
    <Navigate to="/" />
  ) : (
    <ChangePasswordForm
      showAcceptTermsBlock
      processingFormData={isLoading}
      onSubmitHandler={onSubmitHandler}
    />
  );
};
export default RegisterConfirm;
