import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { FALLBACK_LANGUAGE, LANGUAGE_OPTIONS } from '../i18n';

export const LanguageDetector: FunctionComponent = () => {
  const { i18n } = useTranslation();
  const { locale } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!LANGUAGE_OPTIONS.includes(locale || '')) {
      const pathWithValidLocale = `/${FALLBACK_LANGUAGE}/${location.pathname.split('/').slice(2).join('/')}`;

      i18n.changeLanguage(FALLBACK_LANGUAGE);

      navigate(
        {
          pathname: pathWithValidLocale,
          search: window.location.search,
          hash: window.location.hash,
        },
        {
          replace: true,
        }
      );
    }
  }, []);

  return <Outlet />;
};
