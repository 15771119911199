import { Box, Stack, TextField, Typography } from '@mui/material';
import { useQRCode } from 'next-qrcode';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import {
  clearMessage,
  showMessage,
} from '../../../../features/message/message-slice';
import { setPageTitle } from '../../../../features/page-title/page-title-slice';
import { setupSoftwareToken } from '../../../../features/sign-in/services/direct-auth';
import { resetSignInProcess } from '../../../../features/sign-in/sign-in-slice';
import { logError } from '../../../../helper/LoggerHelper';
import { getMessagesFromResponseError } from '../../../../helper/request-helper';
import { SubmitForm } from '../form/submit-form';

type FormValues = {
  softwareToken: string;
};

type AssociateTotpSoftwareTokenProps = {};
export const AssociateTotpSoftwareToken: FunctionComponent<
  AssociateTotpSoftwareTokenProps
> = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const { Canvas } = useQRCode();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const [submitIsLoading, setSubmitIsLoading] = useState<boolean>(false);
  const [responseError, setResponseError] = useState<string | null>(null);

  const { username, selectedInstance, challengeParams } = useAppSelector(
    (state: RootState) => state.signInStore
  );

  const mfaAccountTitle = `Planerio (${selectedInstance?.host.split('.').shift()})`;
  const secret = challengeParams?.secretCode;
  const issuer = selectedInstance?.host;

  const isDataValid =
    !!selectedInstance?.host && !!username && !!secret && !!issuer;
  useEffect(() => {
    dispatch(setPageTitle({ title: 'mfaSetup.pageTitle' }));
  }, []);

  const onSubmit: SubmitHandler<FormValues> = async (formData) => {
    dispatch(clearMessage());
    setResponseError(null);
    setSubmitIsLoading(true);
    if (!selectedInstance) {
      return;
    }
    if (!challengeParams) {
      // not possible use case
      // just to make eslint happy
      return;
    }
    try {
      await setupSoftwareToken(
        selectedInstance,
        i18n.language,
        formData.softwareToken,
        challengeParams.session
      );
      dispatch(resetSignInProcess());
      dispatch(
        showMessage({
          type: 'info',
          message: t('mfaSetup.message.success'),
        })
      );
      navigate(`/${i18n.language}/`, { replace: true });
    } catch (e) {
      logError(e);
      const errorMessages: string[] = getMessagesFromResponseError(e);

      if (errorMessages.length === 1) {
        setResponseError(errorMessages[0]);
      } else {
        dispatch(
          showMessage({
            type: 'error',
            messages: errorMessages,
          })
        );
      }
    } finally {
      setSubmitIsLoading(false);
    }
  };

  return (
    <Stack direction="column" spacing={2} alignItems="center">
      <Typography variant="body1" gutterBottom>
        {t('mfaSetup.description')}
      </Typography>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {isDataValid && (
            <Canvas
              text={`otpauth://totp/${mfaAccountTitle}:${username}?secret=${secret}&issuer=${issuer}`}
              options={{
                margin: 3,
                scale: 10,
                width: 200,
                color: {
                  dark: '#000000',
                  light: '#ffffff',
                },
              }}
            />
          )}
        </Stack>
      </Box>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <SubmitForm
          label={t('mfaSetup.form.verificationCode.label')}
          submitBtnText={t('mfaSetup.form.submit.label')}
          submitIsLoading={submitIsLoading}
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            id="totpSetupInputField"
            autoFocus
            inputProps={{ tabIndex: 1 }}
            placeholder={t('mfaSetup.form.verificationCode.placeholder')}
            autoComplete="off"
            fullWidth
            helperText={errors.softwareToken?.message || responseError}
            error={!!errors.softwareToken?.message || !!responseError}
            {...register('softwareToken', {
              onChange: (e) => {
                e.target.value = e.target.value.trim();
              },
              required: t('mfaSetup.form.softwareToken.error.empty') as string,
            })}
          />
        </SubmitForm>
      </Box>
      <Box
        sx={{
          marginTop: '20px',
        }}
      >
        <Typography variant="caption">{t('mfaSetup.infoText')}</Typography>
      </Box>
    </Stack>
  );
};
