import { Alert } from '@mui/lab';
import { Box } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../app/hooks';
import ChangePasswordForm from '../../../../components/change-password-form/change-password-form';
import { showMessage } from '../../../../features/message/message-slice';
import { setPageTitle } from '../../../../features/page-title/page-title-slice';
import {
  confirmNewPasswordChallengeCentralAuth,
  directCompleteTemporaryPasswordRequest,
} from '../../../../features/sign-in/services/direct-auth';
import { updateSignInState } from '../../../../features/sign-in/sign-in-slice';
import { ChallengeParams } from '../../../../features/sign-in/types/ChallengeParams';
import { InstanceEnvironment } from '../../../../features/sign-in/types/InstanceEnvironment';
import { SignInStepType } from '../../../../features/sign-in/types/SignInStepType';
import { logError } from '../../../../helper/LoggerHelper';
import { handleLoginResponse } from '../../../../helper/LoginHelper';
import { getMessagesFromResponseError } from '../../../../helper/request-helper';

type NewPasswordRequiredChallengeProps = {
  newPasswordChallenge: ChallengeParams;
  selectedInstance: InstanceEnvironment;
};

const NewPasswordRequiredChallenge: FunctionComponent<
  NewPasswordRequiredChallengeProps
> = ({ newPasswordChallenge, selectedInstance }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setPageTitle({ title: 'newPasswordRequired.pageTitle' }));
  }, []);

  const onSubmitHandler = async (password: string) => {
    try {
      setIsLoading(true);
      setIsError(false);

      if (selectedInstance.useCentralAuth) {
        const data = await confirmNewPasswordChallengeCentralAuth(
          { ...newPasswordChallenge, password },
          selectedInstance,
          i18n.language
        );
        handleLoginResponse(data, dispatch);
      } else {
        const response = await directCompleteTemporaryPasswordRequest(
          selectedInstance.host,
          password
        );
        if (response.isTermsAccepted) {
          dispatch(updateSignInState(SignInStepType.SignedIn));
        } else {
          dispatch(updateSignInState(SignInStepType.AcceptTerms));
        }
      }

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setIsError(true);
      logError(e);
      const errorMessages: string[] = getMessagesFromResponseError(e);

      dispatch(
        showMessage({
          type: 'error',
          messages: errorMessages,
        })
      );
    }
  };

  return (
    <Box>
      {!isError && (
        <Alert severity="warning">
          {t('newPasswordRequired.titleWarning')}
        </Alert>
      )}
      <ChangePasswordForm
        processingFormData={isLoading}
        onSubmitHandler={onSubmitHandler}
      />
    </Box>
  );
};

export default NewPasswordRequiredChallenge;
